import React from 'react';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AddLocationAlt,
  KeyboardArrowRight,
  LocalActivity,
  Room,
  Style,
  Toc,
} from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import palette from '../../common/theme/palette';
import { useEventsModule } from '../../common/util/permissions';

const MenuItem = ({
  title, link, icon, selected,
}) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
      marginRight: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);

const PoiMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const events = useEventsModule();

  return (
    <List>
      <MenuItem
        title="Pois"
        link="/poi/pois"
        icon={<Room style={{ color: location.pathname.startsWith('/poi/poi') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/poi/poi')}
      />
      <MenuItem
        title={`${t('attractions')}`}
        link="/poi/attractions"
        icon={<AddLocationAlt style={{ color: location.pathname.startsWith('/poi/attraction') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/poi/attraction')}
      />
      {events && (
        <MenuItem
          title={t('reportEvents')}
          link="/poi/events"
          icon={<LocalActivity style={{ color: location.pathname.startsWith('/poi/event') ? 'white' : 'gray' }} />}
          selected={location.pathname.startsWith('/poi/event')}
          iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
        />
      )}
      <MenuItem
        title={t('contents')}
        link="/poi/contents"
        icon={<Toc style={{ color: location.pathname.startsWith('/poi/content') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/poi/content')}
      />
      <MenuItem
        title="Tags"
        link="/poi/tags"
        icon={<Style style={{ color: location.pathname.startsWith('/poi/tag') || location.pathname.startsWith('/poi/tag') ? 'white' : 'gray' }} />}
        selected={location.pathname.startsWith('/poi/tag')}
      />
    </List>
  );
};

export default PoiMenu;
