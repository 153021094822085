import React, { useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MRT_TableContainer, useMaterialReactTable } from 'material-react-table';

const PositionalTable = ({ items, setItems }) => {
  const delItem = (value) => {
    const itemsFiltered = items.filter((item) => item !== value);
    setItems(itemsFiltered);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data: items,
    enableRowOrdering: true,
    enablePagination: false,
    enableSorting: false,
    enableEditing: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => delItem(row.original)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          items.splice(
            hoveredRow.index,
            0,
            items.splice(draggingRow.index, 1)[0],
          );
          setItems([...items]);
        }
      },
    }),
  });

  return <MRT_TableContainer table={table} />;
};

export default PositionalTable;
