const speedConverter = (unit) => {
  switch (unit) {
    case 'kmh':
      return 1.852;
    case 'mph':
      return 1.15078;
    case 'kn':
    default:
      return 1;
  }
};

export const speedUnitString = (unit, t) => {
  switch (unit) {
    case 'kmh':
      return t('sharedKmh');
    case 'mph':
      return t('sharedMph');
    case 'kn':
    default:
      return t('sharedKn');
  }
};

export const speedFromKnots = (value, unit) => value * speedConverter(unit);

export const speedToKnots = (value, unit) => value / speedConverter(unit);

const distanceConverter = (unit) => {
  switch (unit) {
    case 'mi':
      return 0.000621371;
    case 'nmi':
      return 0.000539957;
    case 'km':
    default:
      return 0.001;
  }
};

export const removeSeconds = (date) => {
  if (!date) return null;
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/;
  const [match] = regex.exec(date);
  return match;
};

export const distanceUnitString = (unit, t) => {
  switch (unit) {
    case 'mi':
      return t('sharedMi');
    case 'nmi':
      return t('sharedNmi');
    case 'km':
    default:
      return t('sharedKm');
  }
};

export const distanceFromMeters = (value, unit) => value * distanceConverter(unit);

export const distanceToMeters = (value, unit) => value / distanceConverter(unit);

const altitudeConverter = (unit) => {
  switch (unit) {
    case 'ft':
      return 3.28084;
    case 'm':
    default:
      return 1;
  }
};

export const altitudeUnitString = (unit, t) => {
  switch (unit) {
    case 'ft':
      return t('sharedFeet');
    case 'm':
    default:
      return t('sharedMeters');
  }
};

export const altitudeFromMeters = (value, unit) => value * altitudeConverter(unit);

export const altitudeToMeters = (value, unit) => value / altitudeConverter(unit);

const volumeConverter = (unit) => {
  switch (unit) {
    case 'impGal':
      return 4.546;
    case 'usGal':
      return 3.785;
    case 'ltr':
    default:
      return 1;
  }
};

export const volumeUnitString = (unit, t) => {
  switch (unit) {
    case 'impGal':
      return t('sharedGallonAbbreviation');
    case 'usGal':
      return t('sharedGallonAbbreviation');
    case 'ltr':
    default:
      return t('sharedLiterAbbreviation');
  }
};

export const volumeFromLiters = (value, unit) => value / volumeConverter(unit);

export const volumeToLiters = (value, unit) => value * volumeConverter(unit);

export const voltToPercentage = (value, unit) => {
  const maxVolt = unit + 6;
  const minVolt = unit - 6;
  const rangeVolt = maxVolt - minVolt;
  const valueInRangeVolt = value - minVolt;
  return Math.round((valueInRangeVolt * 100) / rangeVolt);
};

export const photoArrayFiller = (array) => {
  const arraySupport = [];
  array.forEach((element) => {
    Object.getOwnPropertyNames(element.attributes).forEach((type) => arraySupport.push(element.attributes[type]));
  });
  return arraySupport;
};

export const documentType = {
  DL: 'Driving License',
  ID: 'Identity Card/Passport',
  IDL: 'International Driving License',
};

export const status = {
  '-1': 'INVALID',
  0: 'PENDING',
  1: 'VALID',
};

export const jsonToGeoJson = (data) => {
  const features = [];
  data.forEach((element) => {
    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [element.longitude, element.latitude],
      },
      properties: {
        id: element.id,
      },
    });
  });
  const result = {
    type: 'FeatureCollection',
    features,
  };

  return result;
};

export const generateRandomColor = () => Math.floor(Math.random() * 16777215).toString(16);

export const dateToDateLocal = (date) => {
  const dateSplit = date.split(':');
  return `${dateSplit[0]}:${dateSplit[1]}`;
};

export const dateToDate = (date) => {
  const dateSplit = date.split('T');
  return dateSplit[0];
};

export const parseTwoDigit = (number) => (Math.round(number * 100) / 100)
  .toFixed(2)
  .toString()
  .replace('.', ',');

export const centToEur = (number) => (number ? parseTwoDigit(number / 100) : 0);

export const transactionTypeSelector = (transaction) => {
  let transactionName = 'not defined';
  if (transaction.rideId) {
    transactionName = 'Noleggio';
  } else if (transaction.subscriptionId) {
    transactionName = 'Abbonamento';
  } else if (transaction.walletId) {
    transactionName = 'Ricarica Wallet';
  }
  return transactionName;
};
