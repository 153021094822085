import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import { useSuperManager } from '../common/util/permissions';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const TicketsPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const superManager = useSuperManager();

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/ticketitems');
      if (response.ok) {
        const res = await response.json();
        setItems(res);
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'tickets']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('title')}</TableCell>
            <TableCell>{t('price')}</TableCell>
            <TableCell>{t('discount')}</TableCell>
            <TableCell>{t('details')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.columnAction} padding="none">
                <CollectionActions
                  itemId={item.id}
                  editPath="/ticket"
                  endpoint="ticketitems"
                  setTimestamp={setTimestamp}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>{`${item.price / 100} €`}</TableCell>
              <TableCell>{`${item.discount} %`}</TableCell>
              <TableCell>{item.details}</TableCell>
            </TableRow>
          )) : (<TableShimmer columns={3} endAction />)}
        </TableBody>
      </Table>
      {superManager && (<CollectionFab editPath="/ticket" />)}
    </PageLayout>
  );
};

export default TicketsPage;
