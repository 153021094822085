import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditItemView from './components/EditItemView';
import { useTranslation } from '../common/components/LocalizationProvider';
import EditAttributesAccordion from './components/EditAttributesAccordion';
import SettingsMenu from './components/SettingsMenu';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const TicketPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const [item, setItem] = useState();

  const validate = () => item && item.name && item.title && item.price;

  return (
    <EditItemView
      endpoint="ticketitems"
      item={item}
      setItem={setItem}
      validate={validate}
      menu={<SettingsMenu />}
      breadcrumbs={['tickets']}
    >
      {item && (
        <>
          <Accordion defaultExpanded style={{ borderRadius: 20 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
              />
              <TextField
                value={item.title || ''}
                onChange={(event) => setItem({ ...item, title: event.target.value })}
                label={t('title')}
              />
              <TextField
                value={item.price / 100 || '0.01'}
                type="number"
                inputProps={{ step: '0.01', min: '0.01' }}
                onChange={(event) => setItem({ ...item, price: event.target.value * 100 })}
                label={t('price')}
              />
              <TextField
                value={item.discount || '0'}
                type="number"
                inputProps={{ step: '1', min: '0' }}
                onChange={(event) => setItem({ ...item, discount: event.target.value })}
                label={t('discount')}
              />
              <TextField
                multiline
                rows={4}
                value={item.details || ''}
                onChange={(event) => setItem({ ...item, details: event.target.value })}
                label={t('details')}
              />
            </AccordionDetails>
          </Accordion>
          <EditAttributesAccordion
            attributes={item.attributes}
            setAttributes={(attributes) => setItem({ ...item, attributes })}
            definitions={{}}
          />
        </>
      )}
    </EditItemView>
  );
};

export default TicketPage;
