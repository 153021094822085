import React from 'react';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import PeopleIcon from '@mui/icons-material/People';
import SensorsIcon from '@mui/icons-material/Sensors';
import {
  BikeScooter,
  Block,
  ConfirmationNumber,
  ContactEmergency,
  Description,
  Euro,
  KeyboardArrowRight,
  Logout,
  TravelExplore,
  Work,
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { sessionActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  useAdministrator,
  useAutoModule,
  useGuideModule,
  useRestriction,
  useSharing,
  useSuperManager,
  useTicketsModule,
} from '../../common/util/permissions';
import { nativePostMessage } from '../../common/components/NativeInterface';
import palette from '../../common/theme/palette';
import NotificationDot from './NotificationDot';

const MenuItem = ({ title, link, icon, selected, notifications, notificationsNumber, iconEnd }) => (
  <ListItemButton
    key={link}
    component={Link}
    to={link}
    selected={selected}
    sx={{
      '&.Mui-selected': { backgroundColor: palette.primary.main, color: 'white' },
      '&.Mui-selected:hover': { backgroundColor: palette.primary.main, color: 'white' },
      borderRadius: 20,
      marginLeft: 1,
    }}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
    {notifications && notificationsNumber !== 0 && <NotificationDot number={notificationsNumber} size={22} color="red" />}
    {iconEnd && <ListItemIcon>{iconEnd}</ListItemIcon>}
  </ListItemButton>
);

const SettingsMenu = () => {
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const superManager = useSuperManager();
  const autoModule = useAutoModule();
  const sharing = useSharing();
  const guide = useGuideModule();
  const tickets = useTicketsModule();
  const userId = useSelector((state) => state.session.user.id);
  const user = useSelector((state) => state.session.user);
  const newDocuments = useSelector((state) => state.documents.newDocuments);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = async () => {
    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens:
              tokens.length > 1
                ? tokens.filter((it) => it !== notificationToken).join(',')
                : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };

  return (
    <>
      <List>
        {!readonly && (
          <>
            {sharing && (
              <>
                <span style={{ color: 'gray', padding: 5, fontSize: 15 }}>Sharing</span>
                <MenuItem
                  title={t('manageDevices')}
                  link="/devicesManagement/devices"
                  icon={<SensorsIcon style={{ color: location.pathname.startsWith('/devicesManagement/device') ? 'white' : 'gray' }} />}
                  selected={location.pathname.startsWith('/devicesManagement/device')}
                  iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
                />
                <MenuItem
                  title={t('manageRents')}
                  link="/rents/rides"
                  icon={<BikeScooter style={{ color: location.pathname.startsWith('/rents/rides') ? 'white' : 'gray' }} />}
                  selected={location.pathname.startsWith('/rents/rides')}
                  iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
                />
                {autoModule && (
                  <MenuItem
                    title="Documents"
                    link="/documents"
                    icon={<ContactEmergency style={{ color: location.pathname.startsWith('/document') ? 'white' : 'gray' }} />}
                    notifications
                    notificationsNumber={newDocuments}
                    selected={location.pathname.startsWith('/document')}
                  />
                )}
                <Divider />
              </>
            )}
            {guide && (
              <>
                <span style={{ color: 'gray', padding: 5, fontSize: 15 }}>Guide</span>
                <MenuItem
                  title={t('managePoi')}
                  link="/poi/pois"
                  icon={<TravelExplore style={{ color: location.pathname.startsWith('/poi/pois') ? 'white' : 'gray' }} />}
                  selected={location.pathname.startsWith('/poi/pois')}
                  iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
                />
                {tickets && (
                  <MenuItem
                    title={t('tickets')}
                    link="/tickets"
                    icon={<ConfirmationNumber style={{ color: location.pathname.startsWith('/ticket') ? 'white' : 'gray' }} />}
                    selected={location.pathname.startsWith('/ticket')}
                  />
                )}
                <Divider />
              </>
            )}
            {superManager && (
              <MenuItem
                title={t('manageRates')}
                link="/rates/prices"
                icon={<Euro style={{ color: location.pathname.startsWith('/rates/price') ? 'white' : 'gray' }} />}
                selected={location.pathname.startsWith('/rates/price')}
                iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
              />
            )}
            <MenuItem
              title={t('reportTitle')}
              link="/reports/payments"
              icon={<Description style={{ color: location.pathname.startsWith('/reports/payment') ? 'white' : 'gray' }} />}
              selected={location.pathname.startsWith('/reports/payment')}
              iconEnd={<KeyboardArrowRight style={{ alignSelf: 'center' }} />}
            />
            {/* admin && (
              <MenuItem
                title={t('sharedCalendars')}
                link="/calendars"
                icon={<TodayIcon style={{ color: location.pathname.startsWith('/calendar') ? 'white' : 'gray' }} />}
                selected={location.pathname.startsWith('/calendar')}
              />
            ) */}
            {/* admin && (
              <MenuItem
                title={t('sharedComputedAttributes')}
                link="/attributes"
                icon={<StorageIcon style={{ color: location.pathname.startsWith('/attribute') ? 'white' : 'gray' }} />}
                selected={location.pathname.startsWith('/attribute')}
              />
            ) */}
            {/* superManager && (
              <MenuItem
                title={t('sharedMaintenance')}
                link="/maintenances"
                icon={<BuildIcon style={{ color: location.pathname.startsWith('/maintenance') ? 'white' : 'gray' }} />}
                selected={location.pathname.startsWith('/maintenance')}
              />
            ) */}
            <MenuItem
              title={t('settingsUser')}
              link={`/user/${userId}`}
              icon={<PersonIcon style={{ color: location.pathname === `/user/${userId}` ? 'white' : 'gray' }} />}
              selected={location.pathname === `/user/${userId}`}
            />
            {admin && (
              <MenuItem
                title={t('sharedNotifications')}
                link="/notifications"
                icon={<NotificationsIcon style={{ color: location.pathname.startsWith('/notification') ? 'white' : 'gray' }} />}
                selected={location.pathname.startsWith('/notification')}
              />
            )}
          </>
        )}
        <MenuItem
          title={t('sharedPreferences')}
          link="/preferences"
          icon={<SettingsIcon style={{ color: location.pathname.startsWith('/preferences') ? 'white' : 'gray' }} />}
          selected={location.pathname === '/preferences'}
        />
        <Button
          type="reset"
          onClick={handleLogout}
          style={{
            all: 'unset',
            backgroundColor: palette.colors.negative,
            color: palette.colors.white,
            borderRadius: 20,
          }}
        >
          <MenuItem
            title={t('loginLogout')}
            icon={<Logout style={{ color: palette.colors.white }} />}
          />
        </Button>
      </List>
      {superManager && (
        <>
          <Divider />
          <List>
            {admin && (
              <MenuItem
                title={t('settingsServer')}
                link="/server"
                icon={<StorageIcon style={{ color: location.pathname.startsWith('/server') ? 'white' : 'gray' }} />}
                selected={location.pathname === '/server'}
              />
            )}
            <MenuItem
              title={t('settingsUsers')}
              link="/users"
              icon={<PeopleIcon style={{ color: location.pathname.startsWith('/user') ? 'white' : 'gray' }} />}
              selected={
                location.pathname.startsWith('/user') &&
                location.pathname !== `/user/${userId}`
              }
            />
            <MenuItem
              title="Blacklist"
              link="/blacklists"
              icon={<Block style={{ color: location.pathname.startsWith('/blacklist') ? 'white' : 'gray' }} />}
              selected={
                location.pathname.startsWith('/blacklist') &&
                location.pathname !== `/blacklist/${userId}`
              }
            />
            {autoModule && (
              <MenuItem
                title={t('billingInfo')}
                link="/billingAddresses"
                icon={<Work style={{ color: location.pathname.startsWith('/billingAddress') ? 'white' : 'gray' }} />}
                selected={
                  location.pathname.startsWith('/billingAddress')
                }
              />
            )}
            {phone && <MenuItem />}
            {phone && <MenuItem />}
          </List>
        </>
      )}
    </>
  );
};

export default SettingsMenu;
